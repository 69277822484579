<template>
  <b-card-code title="All answers " no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <addNewAnswer />
      </div>
    </b-card-body>

    <b-table
      v-if="answers.length > 0"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="answers"
      :fields="fields"
    >
      <template #cell(imageCover)="data">
        <b-avatar :src="data.item.imageCover" />
        <!-- {{ data.title }} -->
      </template>
      <template #cell(Action)="data">
        <!-- <p>
          Using v-html directive: <span v-html="data.item.description"></span>
        </p> -->

        <span>
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!-- <b-dropdown-item @clieck="">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item> -->
              <editanswer :answer="data.item" />
              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" class="mr-50 text-danger" />
                <span class="mr-50 text-danger">Delete</span>
              </b-dropdown-item> -->
              <deleteanswer
                @deleteanswerFromlist="deleteanswerFromlist"
                :id="data.item._id"
              />
            </b-dropdown>
          </span>
        </span>
        <!-- {{ data }} -->
        <!-- <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge> -->
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <!-- <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group> -->

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="prams.limit"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <template #code>
      <!-- {{ codeKitchenSink }} -->
    </template>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import answersApi from "@/apiServices/answersApi";
import deleteanswer from "@/views/answers pages/delete.vue";
import editanswer from "@/views/answers pages/edit.vue";
import addNewAnswer from "@/views/answers pages/add.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    deleteanswer,
    addNewAnswer,
    editanswer,
  },
  data() {
    return {
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 5,
        title: "",
      },
      options: ["video", "pdf", "powerpoint"],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "imageCover",
          label: "imageCover",
        },
        { key: "title", label: "Title" },
        { key: "isCorrect", label: "isCorrect" },
        { key: "hint", label: "hint" },
        "Action",
      ],
      /* eslint-disable global-require */
      answers: [],
      /* eslint-disable global-require */
      status: [],
      //   codeKitchenSink,
    };
  },
  created() {
    this.getanswers();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;

      this.getanswers();
    },
    filter() {
      this.prams.page = this.currentPage;
      this.prams.title = this.filter;

      this.getanswers();
    },
  },
  methods: {
    deleteanswerFromlist(id) {
      console.log("hi from parent " + id + "******" + this.answers.length);
      this.answers = this.answers.filter(function (obj) {
        return obj._id != id;
      });
      console.log("hi ******" + this.answers.length);
    },
    getanswers() {
      if (this.filter == "") {
        delete this.prams.title;
      }
      answersApi.getanswers(this.prams).then((response) => {
        this.answers = [];
        if (response.data) {
          this.answers = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All answers",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
