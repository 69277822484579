<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>Edit</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <!-- <b-button
        id="show-btn"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        delete
      </b-button> -->

    <!-- modal -->
    <b-modal
      ref="my-modal"
      modal-class="modal-info"
      hide-footer
      title="update answer"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="AnswerInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Answer Details</h5>
              <small class="text-muted"> Enter the Answer Details. </small>
            </b-col>
            <b-col md="9">
              <b-form-group label="title" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="title"
                    v-model="answer.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Answer Tittle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Is Correct" label-for="CorseCoverImage">
                <b-form-checkbox
                  v-model="answer.isCorrect"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Answer Cover Image"
                label-for="CorseCoverImage"
              >
                <b-form-file
                  id="CorseCoverImage"
                  accept="image/*"
                  v-model="answer.imageCover"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Content" label-for="Content">
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required|min:20"
                >
                  <b-form-textarea
                    id="Content"
                    placeholder="Content"
                    v-model="answer.hint"
                    :state="errors.length > 0 ? false : null"
                    rows="8"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </form>
      <b-row variant="info">
        <b-col md="4"> </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-secondary"
            block
            @click="hideModal"
          >
            No
          </b-button>
        </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=""
            variant="outline-info"
            block
            @click="toggleModal"
          >
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span class="mr-50 text-info">update</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import answersApi from "@/apiServices/answersApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";

export default {
  props: ["answer"],

  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      options: ["video", "pdf", "powerpoint"],

      prams: {
        id: "",
      },
    };
  },
  created() {
    console.log(this.answer);
    // this.answer.imageCover = null;
  },
  mounted() {
    // this.answer.imageCover = null;
  },
  methods: {
    updateanswer() {
      answersApi.updateanswer(this.answer).then((response) => {
        if (response.data) {
          // this.course = response.data;
          // console.log(response.data);
          this.$emit("updateanswerFromlist", this.id);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "answer updated",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      if (this.answer.imageCover instanceof File) {
        console.log(this.answer.imageCover + "sdadasdasdasd");

        const imageFormData = new FormData();
        imageFormData.append("image", this.answer.imageCover);
        imagesApi.uploadImage(imageFormData).then((response) => {
          console.log(response);
          this.answer.imageCover = response;
          this.updateanswer();
        });
      } else {
        delete this.answer.imageCover;
        this.updateanswer();
      }
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
};
</script>
